import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeRounded from "@mui/icons-material/HomeRounded";
import PersonRounded from "@mui/icons-material/PersonRounded";
import DirectionsBusRounded from "@mui/icons-material/DirectionsBusRounded";
import PeopleAltRounded from "@mui/icons-material/PeopleAltRounded";
import ExitToAppRounded from "@mui/icons-material/ExitToAppRounded";
import { logoutRequest } from "../api/admin-controllers/account-controller";
import { Link, useHistory, useLocation } from "react-router-dom";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import Typography from "@mui/material/Typography";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "white",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "white",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    },
  }),
}));

// Özel bir ListItem bileşeni
const MenuListItem = styled(ListItem)(({ theme, open, isactive }) => ({
  padding: open ? "8px 16px" : "8px 0",
  margin: "1px 0",
  height: 45,
  borderRadius: "4px",
  backgroundColor: isactive === "true" ? "rgba(0, 0, 0, 0.04)" : "transparent",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  justifyContent: open ? "flex-start" : "center",
}));

// Özel bir icon konteyner bileşeni
const IconContainer = styled(ListItemIcon)(({ theme, open }) => ({
  minWidth: open ? 36 : 0,
  margin: 0,
  display: "flex",
  justifyContent: "center",
  "& .MuiSvgIcon-root": {
    marginLeft: open ? 0 : 0.6,
    fontSize: 24,
  },
}));

export default function AdminPanelLayout({ children }) {
  const history = useHistory();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      let res = await logoutRequest();
      if (res?.status === 200) {
        history.push("/admin");
      }
    } catch (error) {
      // Handle error
    }
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#3C3C3B",
        }}
        open={open}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box></Box>
          <Link
            to="/admin/index"
            style={{
              textDecoration: "none",
              color: "inherit",
              justifySelf: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1)",
                  "& .MuiSvgIcon-root": {
                    transform: "rotate(180deg)",
                    color: "#ff5a5a",
                  },
                  "& .exit-text": {
                    color: "#ff5a5a",
                    textShadow: "0 0 5px rgba(255,255,255,0.5)",
                  },
                },
              }}
            >
              <IconButton
                onClick={handleLogout}
                color="inherit"
                sx={{
                  "& .MuiSvgIcon-root": {
                    transition: "transform 0.5s ease, color 0.3s ease",
                  },
                }}
              >
                <ExitToAppRounded />
              </IconButton>
              <Typography
                className="exit-text"
                variant="caption"
                sx={{
                  color: "white",
                  mt: -1,
                  fontSize: "0.7rem",
                  fontWeight: "500",
                  textAlign: "center",
                  transition: "color 0.3s ease, text-shadow 0.3s ease",
                }}
              >
                ÇIKIŞ
              </Typography>
            </Box>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box padding="10px" display="flex" alignItems="center">
            <img
              src="/assets/logo.svg"
              style={{ height: "auto", width: "100%" }}
            />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ padding: "18px 0 8px 0" }}>
          <Link
            to="/admin/index"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuListItem
              button
              open={open}
              isactive={isActive("/admin/index").toString()}
            >
              <IconContainer open={open}>
                <HomeRounded
                  sx={{
                    color: isActive("/admin/index") ? "#c03a2a" : "#5C5C5B",
                  }}
                />
              </IconContainer>
              <ListItemText
                primary="ANASAYFA"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: isActive("/admin/index") ? 600 : 400,
                    color: isActive("/admin/index") ? "#c03a2a" : "inherit",
                  },
                  display: open ? "block" : "none",
                }}
              />
            </MenuListItem>
          </Link>

          <Link
            to="/admin/users"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuListItem
              button
              open={open}
              isactive={isActive("/admin/users").toString()}
            >
              <IconContainer open={open}>
                <PersonRounded
                  sx={{
                    color: isActive("/admin/users") ? "#c03a2a" : "#5C5C5B",
                  }}
                />
              </IconContainer>
              <ListItemText
                primary="KİŞİLER"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: isActive("/admin/users") ? 600 : 400,
                    color: isActive("/admin/users") ? "#c03a2a" : "inherit",
                  },
                  display: open ? "block" : "none",
                }}
              />
            </MenuListItem>
          </Link>

          <Link
            to="/admin/vehicles"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuListItem
              button
              open={open}
              isactive={isActive("/admin/vehicles").toString()}
            >
              <IconContainer open={open}>
                <DirectionsBusRounded
                  sx={{
                    color: isActive("/admin/vehicles") ? "#c03a2a" : "#5C5C5B",
                  }}
                />
              </IconContainer>
              <ListItemText
                primary="ARAÇLAR"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: isActive("/admin/vehicles") ? 600 : 400,
                    color: isActive("/admin/vehicles") ? "#c03a2a" : "inherit",
                  },
                  display: open ? "block" : "none",
                }}
              />
            </MenuListItem>
          </Link>

          <Link
            to="/admin/non-signal-vehicles"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuListItem
              button
              open={open}
              isactive={isActive("/admin/non-signal-vehicles").toString()}
            >
              <IconContainer open={open}>
                <WifiOffIcon
                  sx={{
                    color: isActive("/admin/non-signal-vehicles")
                      ? "#c03a2a"
                      : "#5C5C5B",
                  }}
                />
              </IconContainer>
              <ListItemText
                primary="SİNYALSİZ ARAÇLAR"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: isActive("/admin/non-signal-vehicles")
                      ? 600
                      : 400,
                    color: isActive("/admin/non-signal-vehicles")
                      ? "#c03a2a"
                      : "inherit",
                  },
                  display: open ? "block" : "none",
                }}
              />
            </MenuListItem>
          </Link>

          <Link
            to="/admin/community"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuListItem
              button
              open={open}
              isactive={isActive("/admin/community").toString()}
            >
              <IconContainer open={open}>
                <PeopleAltRounded
                  sx={{
                    color: isActive("/admin/community") ? "#c03a2a" : "#5C5C5B",
                  }}
                />
              </IconContainer>
              <ListItemText
                primary="GRUPLAR"
                sx={{
                  "& .MuiTypography-root": {
                    fontWeight: isActive("/admin/community") ? 600 : 400,
                    color: isActive("/admin/community") ? "#c03a2a" : "inherit",
                  },
                  display: open ? "block" : "none",
                }}
              />
            </MenuListItem>
          </Link>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
