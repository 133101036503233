import {
  Alert,
  Box,
  Grid,
  Input,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { findAllVehicleWithoutSignalRequest } from "../../api/admin-controllers/vehicle-controller";

const paginationStyles = `
  .pagination-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f8f8f8;
	border-top: 1px solid #e0e0e0;
	padding: 6px 12px;
	border-radius: 0 0 12px 12px;
	gap: 32px;
  }
  
  .pagination-rows-selector {
	display: flex;
	align-items: center;
  }
  
  .pagination-rows-selector label {
	margin-right: 12px;
	color: #606060;
	font-size: 14px;
	font-weight: 500;
  }
  
  .pagination-rows-selector select {
	padding: 6px 12px;
	border-radius: 4px;
	border: 1px solid #dadada;
	background-color: white;
	color: #3a3a3a;
	font-size: 14px;
	cursor: pointer;
	outline: none;
	transition: all 0.2s ease;
	min-width: 80px;
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 8px center;
	padding-right: 30px;
  }
  
  .pagination-rows-selector select:hover {
	border-color: #aaa;
	background-color: #f9f9f9;
  }
  
  .pagination-rows-selector select:focus {
	border-color: #666;
	box-shadow: 0 0 0 2px rgba(0,0,0,0.05);
  }
  
  .pagination-rows-selector select option {
	background-color: white;
	color: #3a3a3a;
  }
  
  .pagination-info {
	display: flex;
	align-items: center;
	color: #606060;
	font-size: 14px;
	font-weight: 500;
  }
  
  .pagination-controls {
	display: flex;
	align-items: center;
  }
  
  .pagination-button {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	border-radius: 4px;
	color: #333;
	cursor: pointer;
	transition: all 0.2s ease;
	font-size: 18px;
  }
  
  .pagination-button:hover:not(:disabled) {
	background-color: rgba(0, 0, 0, 0.05);
  }
  
  .pagination-button:disabled {
	opacity: 0.3;
	cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
	.pagination-container {
	  flex-direction: column;
	  gap: 12px;
	  padding: 12px;
	}
	
	.pagination-rows-selector, .pagination-info, .pagination-controls {
	  width: 100%;
	  justify-content: center;
	}
  }
  `;

export async function getServerSideProps() {
  return { props: {} };
}

const Page = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);

  //ALERTS FUNC
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  //DATAGRID STATES
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  // Custom Footer Component
  const CustomFooter = (props) => {
    const { pageSize, page, totalCount, setPage, setPageSize } = props;
    const start = page * pageSize + 1;
    const end = Math.min((page + 1) * pageSize, totalCount || 0);

    React.useEffect(() => {
      const style = document.createElement("style");
      style.innerHTML = paginationStyles;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    return (
      <div className="pagination-container">
        <div className="pagination-rows-selector">
          <label>Sayfa başına satır:</label>
          <select
            value={pageSize}
            onChange={(e) => {
              const newPageSize = Number(e.target.value);
              setPageSize(newPageSize);
              setPage(0);
            }}
          >
            {[10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div className="pagination-info">
          {totalCount > 0 ? `${start}-${end} / ${totalCount}` : "0-0 / 0"}
        </div>

        <div className="pagination-controls">
          <button
            className="pagination-button"
            disabled={page === 0}
            onClick={() => setPage(page - 1)}
            aria-label="Önceki sayfa"
          >
            <span>&#8249;</span>
          </button>

          <button
            className="pagination-button"
            disabled={end >= totalCount}
            onClick={() => setPage(page + 1)}
            aria-label="Sonraki sayfa"
          >
            <span>&#8250;</span>
          </button>
        </div>
      </div>
    );
  };

  //GET VEHİCLES
  const fetchVehicles = async () => {
    setTableLoading(true);
    try {
      let res = await findAllVehicleWithoutSignalRequest();
      if (res?.data && res.data.length > 0) {
        setData(res.data);
        setFilteredData(res.data);
        setTotalCount(res.data?.length);
        setTableLoading(false);
      } else {
        setData([]);
        setFilteredData([]);
        setTotalCount(0);
        setTableLoading(false);
      }
    } catch (error) {
      setTableLoading(false);
      if (error?.response?.status === 400) {
        setAlert(true);
        setAlertSeverity("error");
        if (Array.isArray(error.response.data.data)) {
          setAlertContent(error?.response?.data?.data);
        } else {
          setAlertContent(new Array(error?.response?.data));
        }
      } else {
        setAlert(true);
        setAlertContent("Sistem yöneticiniz ile iletişime geçiniz!");
        setAlertSeverity("error");
      }
    }
  };

  const filterVehicles = () => {
    setPage(0);
    const filteredVehicles = data.filter(
      (vehicle) =>
        vehicle?.imei?.includes(searchInput) ||
        vehicle?.licensePlate?.includes(searchInput)
    );
    setFilteredData(filteredVehicles);
    setTotalCount(filteredVehicles?.length);
  };

  useEffect(() => {
    if (searchInput && searchInput !== "" && data && data.length > 0) {
      filterVehicles();
    } else {
      if (data && data.length > 0) {
        setFilteredData(data);
        setTotalCount(data?.length);
      } else {
        fetchVehicles();
      }
    }
  }, [searchInput]);

  //DATAGRID DATAS
  const columns = [
    {
      field: "licensePlate",
      headerName: "Plaka",
      flex: 3,
      minWidth: 130,
    },
    {
      field: "imei",
      headerName: "İmei",
      flex: 3,
      minWidth: 130,
    },
    {
      field: "lastSignalDateTime",
      headerName: "Son Sinyal Tarihi",
      flex: 3,
      minWidth: 130,
    },
  ];

  return (
    <Box>
      {alert ? (
        <Snackbar
          open={alert}
          autoHideDuration={10000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            variant="filled"
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {Array.isArray(alertContent)
              ? alertContent &&
                alertContent.map((val, i) => <li key={i}>{val.message}</li>)
              : alertContent && alertContent}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "24px" }} color="dark">
              30 DAKİKA SİNYAL ALINAMAYAN ARAÇLAR
            </Typography>
          </Card>
        </Grid>
        <Grid item md={12} xs={12}>
          <Card variant="outlined">
            <Input
              sx={{
                margin: "1rem",
                width: {
                  xs: "85%",
                  sm: "85%",
                  md: "30%",
                },
              }}
              placeholder="Aramak istediğiniz kelimeyi yazın..."
              onChange={(e) =>
                setTimeout(() => {
                  setSearchInput(e.target.value);
                }, 500)
              }
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {tableLoading ? (
                <Box
                  style={{
                    width: "100%",
                    height: "60vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <object
                    height="150px"
                    width="150px"
                    type="image/svg+xml"
                    data="/assets/serLoading.svg"
                  ></object>
                </Box>
              ) : (
                <>
                  {/* DataGrid container with explicit height */}
                  <div style={{ height: "60vh", width: "100%" }}>
                    <DataGrid
                      rows={filteredData}
                      columns={columns}
                      page={page}
                      pageSize={pageSize}
                      paginationMode="client"
                      rowCount={totalCount}
                      disableSelectionOnClick
                      disableColumnMenu
                      hideFooter={true}
                      autoHeight={false}
                    />
                  </div>

                  {/* Pagination footer */}
                  <div style={{ width: "100%" }}>
                    <CustomFooter
                      pageSize={pageSize}
                      page={page}
                      totalCount={totalCount}
                      setPage={setPage}
                      setPageSize={setPageSize}
                    />
                  </div>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page;
